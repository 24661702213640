*{
  margin: 0px;
  padding: 0px;
  /* outline: dotted 2px red; */
  
}

#header{

  background-color: rgb(163, 220, 255);
  border-bottom: 5px solid rgb(28,72,164);

  height: 65px;
  width: 100vw;

  display:block;

}


#logohead {
  display: flex;
  height: 65px;
  float: left;
  padding-inline: 10px;

}

#logohead > h1 {

  font-family:Tahoma, Verdana, sans-serif;
  font-weight: bolder;
  font-size: 40px;

  line-height: 65px;

  color: black;
}

#logohead > img {
  height: 45px;
  padding-right: 10px;
  align-self: center;
  vertical-align: middle;
}


#daypanel {


  margin-top: 12px;
  margin-bottom: 12px;
  width: 100vw;

  align-items: center;  
  display: inline-flex;


}





#dayselector{

width: 55vw;
height: 40px;

position: relative;
left: 3vw;

background-color:  rgba(0, 136, 255, 0.581);

display: inline-flex;

}

#dayselector > div {

  width: 20%;
  text-align: center;
  outline: 2px solid rgb(28,72,164);

}

.activeday{

  background-color:rgb(163, 220, 255);


}

.inactiveday{



}

#dateIntial{

  height: 40px;
  max-width: 300px;
  margin-left: auto;
  right: 3vw;

  position: relative;
  display: inline-flex;

  background-color:rgb(163, 220, 255);
  outline: 2px solid rgb(28,72,164);

  line-height: 40px;
  padding-left: 5px;

}


#dateIntial > input{

  width: 50px;
  background-color: transparent;
  border: transparent;
  font-size: 14px;
  font-weight: bold;


}



#suitespanel{

  display: block;
  position: relative;

  height: 80vh;
  width: 95vw;
  left: 2.5vw;

  border-top-right-radius:10px;
  border-top-left-radius:10px;


  border-bottom: 2px solid rgb(28,72,164);

}

#suitestab{

  display: inline-flex;
  position: relative;

  width: 100%;


}



#suitestab > div{

display: block;
width: 100%;
height: 40px;


}

#suitestab > div > h2{

text-align: center;
line-height: 40px;
font-size:20px;

  
  }

 

.activeSuite {

  border-top-right-radius:10px;
  border-top-left-radius:10px;

  width: 100%;

  border-left:2px solid rgb(28,72,164);
  border-right:2px solid rgb(28,72,164);
  border-top:2px solid rgb(28,72,164);
  border-bottom: 4px solid rgb(163, 220, 255);

  background-color: rgb(163, 220, 255);

  z-index: 1;

}

.inactiveSuite {

background-color: rgba(0, 136, 255, 0.581);

width: 100%;

border-left: 2px solid rgb(28,72,164);
border-top: 2px solid rgb(28,72,164);
border-right: 2px solid rgb(28,72,164);

border-top-right-radius:10px;
border-top-left-radius:10px;


}


#suitepage{
  position: relative;
  width: 100%;
  height: 100%;

  background-color: rgb(163, 220, 255);

  /* border-bottom: 2px solid rgb(28,72,164);
  border-left: 2px solid rgb(28,72,164);
  border-right: 2px solid rgb(28,72,164); */

  outline-offset: -2px;
  outline: 2px solid rgb(28,72,164);
  top: -4px;



}



#roomstab{

  display: inline-flex;
  position: relative;

  height: 40px;
  width: 95%;
  left: 2.5%;
  
  margin-top: 30px;

  border-radius: 25px;
  background-color: white;

  text-align: center;
  line-height: 40px;
  font-size: 25px;

  border: 2px solid rgb(28,72,164);
}

#roomstab > div {
  width: 100%;

}

.activeRoom {
  background-color: rgb(28,72,164);
  color: white;
  border-radius: 20px;

}

.inactiveRoom {

}





#pumpform{
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 30px;
  height:665px;

}

#pumpform > h1{

  margin-inline: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-bottom: 15px;
}



.pumpcolumns{

  position: relative;
  display: inline-flex;
  width: 97.5%;
  left: 1.25%;
  height: fit-content;

}

.CWP{

  display: block;
  padding: 15px;

  align-self: center;
  border-radius: 10px;
  margin: auto;
  width: 42.5%;
  height: inherit;

  background-color: lightgrey;
  border: 3px solid rgb(28,72,164);

}

.cwptitle {
  
  display: flex !important;
  font-size: 1.5em !important;
  padding-bottom: 15px;
  

  /* outline: 1px solid red; */

}

.cwptitle > h3 {

  position: relative;
  margin-left: auto;


}




.CWP > div {

  list-style: none;
  display: block;
  position: relative;
  font-size: 50px;
  font-family: sans-serif;
  font-weight: 900;

  height: 50%;


}

.CWP > div > div {

  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  height: 100%;


}



.pumponinput {

  padding-left: 24px;

}


 .formbar {
  width: 50px;
  font-size: 30px;

 }




 #dataRoomForm{

  width: 95%;
  position: relative;
  left: 2.5%;

 }



 .panelandroominspection{

  background-color: lightgrey;
  border: 3px solid rgb(28,72,164);
  border-radius: 10px;
  padding: 5px;
  text-align: center;


  list-style: none;

  margin-top: 10px;



 }

 .panelandroominspection > li {

  position: relative;
  display: inline-flex;
  padding-top: 5px;
  padding-bottom: 5px;


 }

.panelroominputs{

  padding-left: 2px;

  width: 50px;

}




#cratesdiv{

  background-color: lightgrey;
  outline: 3px solid rgb(28,72,164);
  border-radius: 10px;

  position: relative;
  display: block;
  width: 100%;
  margin-top: 25px;


}

#cratesnavbardiv{

  width: 100%;
  padding-top: 12px;

  display: flex;

}

#cratesnavbar{

  display: inline-flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  line-height: 50px;
  font-size: 15px;

}

#cratenumber {

width: 50px;
font-size: 30px;
font-weight: 900;

-moz-appearance: none;

outline: 1px solid black;
text-align: right;


}


#cratesweeklyinfo{



  margin-inline: 2.5%;
  margin-top: 12px;

  display: flex;

  width: 95%;



  /* outline: 1px solid black; */
  



}

#cratesTitles{

  font-size: 30px;
  font-weight: bolder;
  min-width: 120px;
  max-width: 190px;
  
}


#cratesTitles > div {


  padding: 10px;
  outline: 1px solid black;

}

#cratesday {

  display:flex;
  width: 100%;

}

#cratesday > div {


  font-size: 30px;
  font-weight: bolder;
  padding-bottom: 15px;
  text-align: center;

  

}




.daycoloum{

  width: 30%;

}


.selecteddaycoluum{

  width: 50%;

}

.daycoloum > div{

  padding: 10px;
  outline: 1px solid black;
}

.selecteddaycoluum > div {

  padding: 10px;
  outline: 1px solid black;
  background-color: white;
}

#humidity{
  font-size: 24px !important;
  height: 35.5px;
}





.datacratenumberinput{

  position: relative;
  display: block;

  padding: 0px !important;

}


.datacratenumberinput > input{

  width: 23vw;
  height: 55.5px;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  background-color: white;
  border: transparent;

}


#PDUdiv{

  display: block;
  margin-top: 25px;



}


#PDU{

  display: flex;
  width: 100%;

}


#PDUtabs {

  position: relative;
  display: block;

  width: 50px;
  height: 200px;

}

#PDUtabs > ul {

  list-style: none;

}

#PDUtabs > ul > li {


  position: relative;
  display: flex;
  height: 100px;

  vertical-align: middle;
  justify-content: center;
  align-items: center;


  text-align: center;
  font-size: 30px;
  font-weight: 600;
  


}

.selectedPDU{
  
  background-color: lightgrey;

  border-top: 3px solid rgb(28,72,164);
  border-left: 3px solid rgb(28,72,164);
  border-bottom: 3px solid rgb(28,72,164); 
  border-right: 3px transparent;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; 

  height: 97px !important;

}

.unselectedPDU{

  background-color: rgb(147, 145, 145);

  border-top: 3px solid rgb(28,72,164);
  border-left: 3px solid rgb(28,72,164);
  border-bottom: 3px solid rgb(28,72,164);
  border-right: 3px solid rgb(28,72,164);

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  height: 97px !important;

 

}


#PDUchart {


  height: 200px;
  background-color: lightgray;

  position: relative;
  display: flex;

  border-top: 3px solid rgb(28,72,164);
  border-right: 3px solid rgb(28,72,164);
  border-bottom: 3px solid rgb(28,72,164);
  
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;


  /* align-self: center;
  align-content: center;
  justify-content: center; */



}

#PDUnumber{

  width: 50px;
  height: 175px;
  position: relative;
  top: 12.5px;

  margin-inline: 20px;


  list-style:none;

  
  border: 3px solid rgb(28,72,164);
 

}

#PDUnumber > li {

  display: flex;
  position: relative;

  vertical-align: middle;
  justify-content: center;
  align-items: center;

  height: 25%;

  font-size: 30px;
  font-weight: 600;

}

.selectedPDUnumber {

background-color: white;

  outline: 1px black solid;

}

.unselectedPDUnumber {

  background-color: rgb(147, 145, 145);
  outline: 1px black solid; 

}

#PDUtable{

  display: flex;
  position: relative;

  height: 175px;



  margin-top: 15px;


}


#amps {
  font-size: 22.5px !important;
}


#PDUtable > ul {

  list-style: none;
  height: 175px;

  outline: 3px solid black;

}

#PDUtable > ul > li {


  font-size: 25px;
  font-weight: 700;

  padding: 10px;
  height: 38.33px;
  width: 7.5vw;

  text-align: center; 

}


#PDUtable > ul > input {

  outline: 2px solid black;
  font-size: 25px;
  font-weight: 700;

  width: 9.9974974975vw;
  height: 54.33px;
  text-align: center;

  position: relative;
  display: block;

}

#pdualarmphoto{

  width: 12.5vw;
  height: 175px;
  position: relative;
  display: block;

  margin-right: 12px;

  text-align: center;

  outline: 3px solid black;

}

#pdualarmphoto > div{

  position: relative;
  display: flex;
 
  margin-inline: 10px;
  justify-content: center;

  margin-top: 12px;
  font-size: 24px;

}


#alarmphoto{

  width: 50px;

}

.pdualarm {
  width: 20px;
  height: 20px;
}

#pdualarmphoto > h2 {
  margin-top: 7.5px;
}





#upsroomdiv{

  display: block;
  padding-inline: 15px;
  left: 2.5%;


  height: 650px;

}


#upsroomdiv > div {
  position: relative;
  display: block;


  margin-top: 12px;


  padding:12px;



  background-color: lightgrey;
  outline: 3px solid rgb(28,72,164);
  border-radius: 10px;
}

#upsroomdiv > div > h1{

  margin-bottom: 12px;
  
}

#inline1516 {

  display: flex !important;
  position: relative !important;
  width: 97.25%;
  margin-top: 24px !important;


}

#crates1516{

  width: max-content;
  height: min-content;

  display: flex;
  position: relative;


}

#crates1516 > ul{

list-style: none;
width: 33%;


}

.humidity1516 {
  font-size: 20px !important;
}

#crates1516 > ul > li {

  text-align: center;

  position: relative;
  display: flex;

  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  vertical-align: center;

  height: 40px;


  font-size: 25px;
  font-weight: 700;
  padding: 5px;

  outline: 3px solid black;
  
  }

  .crate1516input {

    padding: 0px !important;
    height: 50px !important;




  }

  .crate1516input > input{

    height: 50px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important;

    font-size: 30px;
    font-weight: 500;
    text-align: center;

    /* width: 6.774259171356886vw !important; */
    width: 100%;
  
  }


  #upsSystemSwitch {

    position: relative;
    display: flex;
    margin-top: 12px;
    margin-bottom: 24px;


    align-items: center;
    text-align: center;


  }

  #upsSystemSwitch > ul {

  list-style: none;
  display: flex;
  position: relative;

  width: 70px;
  height: 35px;

  border: 3px solid rgb(28,72,164);
  background-color: white;

  border-radius: 30px;
  margin-left: 15px;


  }

  #upsSystemSwitch > ul > li {

  font-size: 25px;
  font-weight: 900;

  position: relative;
  display: flex;

  align-items: center;
  text-align: center;
  justify-content: center;

  width: 70px;
  height: 35px;
  
  }

.selectedups{

  background-color: rgb(28,72,164);
  color: white;
  border-radius: 40px;
}

#upsstatus1{
  padding-bottom: 24px !important;
  margin-bottom: 24px !important;
}


#upssystem > span {

  display: flex;
  position: relative;


}

#upssystem > span > input {

  margin-inline: 12px;
  width: 20px;
  height: 20px;

}





#USPstatus{

  display: flex;
  position: relative;


  width: 100%;
  height: 40px;
  margin-top: 12px;


}

#USPstatus > div {
  display: flex;
  width: 25%;
  height: 40px;

  display: flex;
  padding: 5px;
  outline: 2px solid black;
  text-align: center;

  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;

  vertical-align: middle;

  
}





.UPScheckboxes{

  margin-left: 10px;
  width: 20px;
  height: 20px;

}

.UPSradio {
  width: 33%;
  /* outline: 2px solid red; */
  display: flex;




  justify-content: center;

 

}

.UPSradio > div {
  display: flex;

}

.UPSradio > div > input{

  width: 20px;
  height: 20px;

}

.UPSnumbers{

  text-align: center;
  width: 75px;
  height: 25px;
  font-size: 24px;
  font-weight: 500;
  margin-left: 5px;

}









#mainupsSystem {

  outline: 2px solid black;

}

#mainupsSystem > div {

  display: flex;


 
  
}

#mainupsSystem > div > div {

  display: flex;
  padding: 10px;
  text-align: left;
  line-height: 25px;


  outline: 2px solid black;
  width: 50%;

}



#redundancyMeter{

  height: 25px;
  width: 50px;

  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-left: 10px;

}





#genPage{

  height: 100%;
  width: 95%;
  position: relative;
  display: block;
  padding-inline: 2.5%;
  padding-top: 24px;

}


#genNavPage{

  width: 95%;

  position: relative;
  display: block;
  padding-inline: 2.5%;


  padding-bottom: 24px;

  background-color: lightgray;
  outline: 3px solid rgb(28,72,164);
  border-radius: 15px;

}


#genNavDiv{

  position: relative;
  display: flex;
  justify-content: center;



}


#genNav{

  display: flex;
  height: 50px;
  width: min-content;
  margin-top: 12px;


  /* outline: 4px solid purple; */

}

#genNav > div {

  display: flex;
  position: relative;
  align-items: center;

  padding: 10px;

}





#genTable {

display: block;
position: relative;
outline: 3px solid black;
margin-top: 12px;

}

#genTable > div {

  display: flex;
  position: relative;
  width: 100%;
  height: 100px;


}

#genTable > div > div {

  display: flex;

  text-align: left;
  line-height: 25px;
  font-size: 30px;
  outline: 2px solid black;
  width: 50%;
  height: 100%;



}

#genTable > div > div > div {

  display: flex;
  position: relative;

  width: 66%;
  height: 100%;

  padding-inline: 12px;

  align-items: center;
  justify-content: center;

}






.genCheckbox{

  width: 40px;
  height: 40px;
  margin-right: 12px;

}

.genNumberinput{


  font-size: 30px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  height: 75%;
  margin-right: 12px;
  
}

.genInputDiv{

  width: 33% !important;
  padding-inline: 0px !important;
  display: flex;
  position: relative;

}

.genNumberinput2 {
  display: block;
  outline: solid 3px red;
}


#RMeter {

padding-inline: 20px;

}




#chillerPage{

  width: 95%;
  padding-inline: 2.5%;
  margin-top: 24px;

}

#chillerdiv {
  background-color: lightgray;
  outline: 3px solid rgb(28,72,164);
  border-radius: 15px;

  padding-bottom: 12px;


}


.chiller{

  width: 90%;
  margin-inline: 5%;
  margin-bottom: 24px;



  display: block;
  position: relative;

}



.chillinputnumber > input{

  height: 40px;
  width: 100px;
  
  font-size: 30px;
  font-weight: 500;

}

.chillinputcheckbox > input{ 

width: 30px;
height: 30px;
vertical-align: middle;
}



.chillerTitle {

  text-align: center;
  padding: 12px;
  outline: 0px transparent solid !important;

}


.ChillerTablediv{

display: block;
position: relative;


}

.ChillerTablediv > div{

  display: flex;
  position: relative;
  width: 100%;



}

.ChillerTablediv > div > div{

  display: flex;
  position: relative;
  width: 100%;

  outline: 3px solid black;

  text-align: center;
  font-size: 25px;
  padding: 12px;
 



}

.ChillerTablediv > div > div > div {

  display: flex;
  position: relative;
  width: 100%;
  padding: 5px;

  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  vertical-align: center;

}



#error{

  display: block;
  position: relative;

  text-align: center;
  width: 95%;
  height: 60%;

}

#error > div{

  width: 95%;
  text-align: center;
  padding-top:100px;
  padding-bottom: 24px;
  font-size: 30px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}


#camara{
  width: 35px;
  padding-inline: 30px;

}

#notes{
  width: 25px;
  padding-inline: 30px;
}

#roomnotescamara{

  display: flex;
  position: relative;

  padding-top: 12px;

  text-align: center;
  font-family: 'Arial Narrow', Arial, sans-serif;




  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  vertical-align: center;

}

.inactivemodal{

display: none;


}

.activemodal{

  display: fixed;
  position: fixed;
  z-index: 2;

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background-color: rgba(0, 94, 255, 0.372);

}

.activemodal > div {

outline: 3px solid blue;

display: block;
position: relative;
z-index: 3;

width: 80vw;
height: 75vh;

top: 12.5vh;
left: 10vw;

background-color: lightgray;
outline: 3px solid rgb(28,72,164);
border-radius: 15px;

}


.activemodal > div > h1 {

font-size: 35px;
padding-bottom: 12px;

}

.notesfield {

width: 92.5%;
height: 100px;
font-size: 20px;

}




.exitmodal {

display: block;
position: relative;

height: 25px;
width: max-content;

margin-left: auto;


padding: 15px;
font-size: 40px;

}

.modalbutton{

  display: flex;
  position: absolute;

  bottom: 0px;

  width:95%;


  margin-inline: 2.5%;



}

.modalbutton > div {

display: flex;
position: relative;
width: 100%;
background-color: white;
outline: 3px solid rgb(28,72,164);
border-radius: 10px;

align-items: center;
justify-content: center;

margin: 24px;
font-size: 20px;

}

#subjectemail {

  display: flex;
  position: relative;
  
  height: 40px;
  width: 100%;
  margin-bottom: 12px;

}

#subjectemail > input {

  width: 100%;
  margin-inline: 2.5vw;
  padding-inline: 12px;

  font-size: 25.5px;

  
  }

#dataplategen{

display: flex;
position: relative;
left:120px;


justify-content: center;
align-items: center;
vertical-align: middle;


}

.pastNotesdiv{

  display: block;
  position: relative;

  width: 90%;
  margin-inline: 5%;

  height: 350px;

  overflow-y: scroll;

  background-color: white;
  outline: 3px solid rgb(28,72,164);
  border-radius: 10px;
}


.singleNotes {
  display: block;
  position: relative;

  outline: 2px solid black;

  padding: 12px;
  margin-bottom: 24px;
  text-overflow: 100%;

  background-color: lightgray;

}

.singleNotes > h2{



}

.singleNotes > div > p{

  padding-top: 5px;


}



.imagediv {

  display: block;
  position: relative;

  outline: 2px solid black;

  padding: 12px;
  margin-bottom: 24px;
  text-overflow: 100%;

  background-color: lightgray;

}

.imagediv > div > h1 {
  
  
}

.imagediv > div > p {
  
  
}

.imagediv > div > img {
  
max-width: 400px;

margin-bottom: 100px;

}

.switchon{

  outline: solid 5px green !important;
  background-color: lightgreen !important;
  padding-inline: 6px !important;
  font-weight: bold !important;
  align-self: center !important;
  z-index: 1 !important;

}

.switchoff{
  outline: solid 5px red !important;
  background-color: tomato !important;
  padding-inline: 6px !important;
  font-weight: bold !important;
  align-self: center !important;


}



.pumpdataplate {

  display: fixed;
  position: fixed;
  z-index: 2;

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background-color: rgba(0, 94, 255, 0.372);


}

.pumpdataplate > div {

  outline: 3px solid blue;

  display: block;
  position: relative;
  z-index: 3;

  width: 80vw;
  height: 75vh;

  left: 10vw;
  top: 12.5vh;

  background-color: lightgray;
  outline: 3px solid rgb(28,72,164);
  border-radius: 15px;


}

.pumpdataplate > div > div {

  display: block;
  position: absolute;

  width: 80%;
  height: 92.5%;

  padding-inline: 10%;

  text-align: center;
  font-size: 20px;
}

.pumpdataplate > div > div > img {

  width: 100%;
  height: 80%;
}